import React, {useLayoutEffect, useEffect, useRef} from 'react';
import {Transition} from '@headlessui/react';

import {bgColorClassnames, Panel} from '@sphericsio/design-system';

type ModalProps = {
    onClose: () => void;
    title: string;
    isOpen: boolean;
    children: React.ReactNode;
};
export function Modal({children, onClose, isOpen}: ModalProps) {
    const [actuallyOpen, setActuallyOpen] = React.useState(false);
    const timeoutId = useRef<any>();
    const scrollbarMeasureRef = useRef<HTMLDivElement | null>();
    useEffect(() => {
        if (!isOpen) {
            clearTimeout(timeoutId.current);
            document.body.classList.remove('overflow-y-hidden');
            document.body.style.paddingRight = '0';
        } else {
            setActuallyOpen(isOpen);
        }
    }, [isOpen, setActuallyOpen]);
    useLayoutEffect(() => {
        if (isOpen) {
            document.body.classList.add('overflow-y-hidden');
            if (scrollbarMeasureRef.current) {
                const el = scrollbarMeasureRef.current;
                const scrollbarWidth = el.offsetWidth - el.clientWidth;
                document.body.style.paddingRight = `${scrollbarWidth}px`;
            }
        }
    }, [isOpen]);

    const close = () => {
        setActuallyOpen(false);
        timeoutId.current = setTimeout(() => {
            onClose();
        }, 200);
    };

    if (!isOpen) {
        return null;
    }

    return (
        <>
            <div
                ref={(ref) => (scrollbarMeasureRef.current = ref)}
                className="w-96 h-96 overflow-scroll absolute"
                style={{top: -9999}}
            />
            <div className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-center justify-center min-h-full pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition
                        show={actuallyOpen}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 transition-opacity">
                            <div aria-hidden="true" onClick={() => close()}>
                                <div
                                    className={`absolute inset-0 ${bgColorClassnames(
                                        'modal-bg',
                                    )} opacity-60`}
                                ></div>
                            </div>
                        </div>
                    </Transition>

                    {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition
                        show={actuallyOpen}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block overflow-hidden transform transition-all max-w-5xl">
                            <Panel>
                                <div
                                    role="dialog"
                                    aria-modal="true"
                                    aria-labelledby="modal-headline"
                                >
                                    {children}
                                </div>
                            </Panel>
                        </div>
                    </Transition>
                </div>
            </div>
        </>
    );
}
